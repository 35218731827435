<template>
  <!-- 人才招聘 -->
  <div class="recruit">
    <Banner
      :banner_img="resBanners"
      banner_en="TALENT RECRUITMENT"
      banner_cn="人才招聘"
    >
    </Banner>
    <div class="contain">
      <!-- 人才理念 -->
      <!-- <div class="c-space c-space01">
                <div class="noticetit marginT40">人才理念</div>
                <div class="marginT20">
                    <ul class="recruit_con mainBodyA">
                        <li>
                            <div class="recruit_tit fontsize18 color333">
                                德才兼备
                            </div>
                            <div class="recruit_ftit fontsize14 marginT20">
                                才者，德之资也；德者，才之帅也
                            </div>
                        </li>
                        <li>
                            <div class="recruit_tit fontsize18 color333">
                                德才兼备
                            </div>
                            <div class="recruit_ftit fontsize14 marginT20">
                                才者，德之资也；德者，才之帅也
                            </div>
                        </li>
                        <li>
                            <div class="recruit_tit fontsize18 color333">
                                德才兼备
                            </div>
                            <div class="recruit_ftit fontsize14 marginT20">
                                才者，德之资也；德者，才之帅也
                            </div>
                        </li>
                        <li>
                            <div class="recruit_tit fontsize18 color333">
                                德才兼备
                            </div>
                            <div class="recruit_ftit fontsize14 marginT20">
                                才者，德之资也；德者，才之帅也
                            </div>
                        </li>
                    </ul>
                </div>
            </div> -->
      <!-- 招聘流程 -->
      <div class="c-space c-space02 marginT40 wow fadeInUp animated">
        <!-- <div class="banner ">
                    <img src="../assets/images/recruit/fgbanner.png" />
                </div> -->
        <div class="list-process clear">
          <div
            :class="[
              effectShow ? 'show' : 'hide',
              'item-process' + (index - 0 + 1),
              'item-process',
            ]"
            v-for="(item, index) in processList"
            :key="index"
          >
            <div class="p-icon p-icon01">
              <img :src="item.imgsrc" :alt="item.info" />
            </div>
            <div class="p-text">{{ item.info }}</div>
            <div class="p-num">{{ item.num }}</div>
            <div
              class="jiantou"
              v-if="index - 0 + 1 < processList.length"
            ></div>
          </div>
        </div>
      </div>
      <!-- 岗位招聘 -->
      <div class="mainBodyA wow fadeInUp animated">
        <div class="noticetit marginT40">岗位招聘</div>
        <div class="marginT20 tabOcon">
          <div class="tabhead">
            <div class="tabline25">职位名称</div>
            <div class="tabline25">学历要求</div>
            <div class="tabline25">工作地点</div>
            <div class="tabline25">招聘人数</div>
          </div>
          <el-collapse class="space-collapse" v-model="activeName" accordion>
            <el-collapse-item
              v-for="(item, index) in recruitList"
              :key="index"
              :name="index"
              :class="[index % 2 == 1 ? 'collapseItem1' : 'collapseItem2']"
            >
              <template slot="title">
                <div class="tabTr" :index="index">
                  <div class="tabline25 color333">
                    {{ item.positionName }}
                  </div>
                  <div class="tabline25 color333">
                    {{ item.degreeRequired ? item.degreeRequired : "不限" }}
                  </div>
                  <!-- <el-tooltip
                                        class="item"
                                        :content="item.workingPlace"
                                        placement="top"
                                    >
                                        <div class="tabline25 color333">
                                            {{
                                                item.workingPlace
                                            }}
                                        </div>
                                    </el-tooltip> -->
                  <div class="tabline25 color333" :title="item.workingPlace">
                    {{ item.workingPlace }}
                  </div>
                  <div class="tabline25 color333">
                    {{ item.recruitCount }}
                  </div>
                  <div class="down down00 cusorS">
                    {{ activeName === index ? "收起" : "展开" }}
                  </div>
                </div>
              </template>
              <div class="recruitCon recruitCon01 clear">
                <div class="recruitCon_bg marginR30 fl">
                  <div class="recruitCon_tit">岗位职责</div>
                  <div
                    class="recruitCon_con"
                    v-html="item.jobResponsibility"
                  ></div>
                </div>
                <div class="recruitCon_bg fl">
                  <div class="recruitCon_tit">岗位要求</div>
                  <div class="recruitCon_con" v-html="item.jobDesc"></div>
                </div>
              </div>
              <div class="recruitbtm">
                <div class="recruitbtm_line">
                  <img src="../assets/images/recruit/icon1.png" />
                  <span>招聘邮箱：{{ item.deliverEmail }}</span>
                </div>
                <div class="recruitbtm_line">
                  <img src="../assets/images/recruit/icon2.png" />
                  <span>联系人：{{ item.contactUser }}</span>
                </div>
                <div class="recruitbtm_line">
                  <img src="../assets/images/recruit/icon3.png" />
                  <span>联系电话：{{ item.contactPhone }}</span>
                </div>
                <div class="recruitbtm_line">
                  <img src="../assets/images/recruit/icon4.png" />
                  <span>联系地址：{{ item.contactAddress }}</span>
                </div>
              </div>
              <div class="recruitbtm_ts">如有应聘意向者请投简历至招聘邮箱~</div>
            </el-collapse-item>
          </el-collapse>
          <!-- 分页 -->
          <div class="pagecon">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageSize"
              :total="total"
              @size-change="getPage"
              @current-change="getPage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem, tooltip } from "element-ui";
import Banner from "@/components/common/Banner.vue";

export default {
  data() {
    return {
      activeName: 0,
      processList: [
        {
          imgsrc: require("../assets/images/recruit/1.png"),
          info: "简历投递",
          num: "01",
        },
        {
          imgsrc: require("../assets/images/recruit/2.png"),
          info: "简历筛选",
          num: "02",
        },
        {
          imgsrc: require("../assets/images/recruit/3.png"),
          info: "笔试",
          num: "03",
        },
        {
          imgsrc: require("../assets/images/recruit/4.png"),
          info: "面试",
          num: "04",
        },
        {
          imgsrc: require("../assets/images/recruit/5.png"),
          info: "录用通知",
          num: "05",
        },
        {
          imgsrc: require("../assets/images/recruit/6.png"),
          info: "入职报到",
          num: "06",
        },
      ],
      recruitList: [],
      total: 1, // 总数
      pageNo: 1, // 当前页
      pageSize: 5, // 每页显示条数
      effectShow: false,
      resBanners: [],
    };
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      that.effectShow = true;
    }, 400);
    this.getDataList();
    this.getBanners();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "6",
      });
      if (res.code === 200) {
        this.resBanners = res.rows;
      }
    },
    async getDataList() {
      let res = await this.$request.getRecruitPage({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      // let dataList = [];
      if (res.code === 200) {
        this.recruitList = res.rows;
        this.total = res.total;
      }
      // console.log(res);
    },
    async getPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.pageNo = page;
      this.getDataList();
      this.activeName = 0;
    },
  },
  components: {
    Collapse,
    CollapseItem,
    tooltip,
    Banner,
  },
};
</script>

<style lang="scss" scoped>
.recruit_con {
  font-size: 0;
  li {
    width: 14.84vw;
    height: 10.99vw;
    display: inline-block;
    margin-right: 1.04vw;
    cursor: pointer;
    transition: 0.6s ease-in-out;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(1):hover {
      background: url("../assets/images/recruit/bg1on.png") no-repeat;
      background-size: 100%;
    }
    &:nth-child(2):hover {
      background: url("../assets/images/recruit/bg2on.png") no-repeat;
      background-size: 100%;
    }
    &:nth-child(3):hover {
      background: url("../assets/images/recruit/bg3on.png") no-repeat;
      background-size: 100%;
    }
    &:nth-child(4):hover {
      background: url("../assets/images/recruit/bg4on.png") no-repeat;
      background-size: 100%;
    }

    &:nth-child(1) {
      background: url("../assets/images/recruit/bg1.png") no-repeat;
      background-size: 100%;
    }
    &:nth-child(2) {
      background: url("../assets/images/recruit/bg2.png") no-repeat;
      background-size: 100%;
    }
    &:nth-child(3) {
      background: url("../assets/images/recruit/bg3.png") no-repeat;
      background-size: 100%;
    }
    &:nth-child(4) {
      background: url("../assets/images/recruit/bg4.png") no-repeat;
      background-size: 100%;
    }
  }
  .recruit_tit {
    margin-top: 2.6vw;
    text-align: center;
  }
  .recruit_ftit {
    font-size: 0.73vw;
    text-align: center;
  }
}
.c-space02 {
  width: 62.5vw;
  margin: 0 auto;
  .list-process {
    height: 8.23vw;
    position: relative;
    .item-process {
      display: block;
      padding: 0 7.03vw 0 0;
      text-align: center;
      // position: relative;
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease-in-out;
      position: absolute;
      top: 0;
      opacity: 0;
      &.hide {
        opacity: 0;
      }
      &.item-process1 {
        transition-delay: 0.7s;
        left: 0;
        &.show {
          opacity: 1;
          left: 0;
        }
      }
      &.item-process2 {
        transition-delay: 0.7s * 2;
        left: 0;
        &.show {
          opacity: 1;
          left: 11.56vw * 1;
        }
      }
      &.item-process3 {
        transition-delay: 0.7s * 3;
        left: 11.56vw * 1;
        &.show {
          opacity: 1;
          left: 11.56vw * 2;
        }
      }
      &.item-process4 {
        transition-delay: 0.7s * 4;
        left: 11.56vw * 2;
        &.show {
          opacity: 1;
          left: 11.56vw * 3;
        }
      }
      &.item-process5 {
        transition-delay: 0.7s * 5;
        left: 11.56vw * 3;
        &.show {
          opacity: 1;
          left: 11.56vw * 4;
        }
      }
      &.item-process6 {
        left: 11.56vw * 4;
        transition-delay: 0.7s * 6;
        &.show {
          opacity: 1;
          left: 11.56vw * 5;
        }
      }
      &:last-child {
        padding-right: 0;
      }
      .p-icon {
        width: 4.58vw;
        height: 4.58vw;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
      }
      .p-text {
        color: #333333;
        padding: 0.52vw 0;
        font-size: 0.83vw;
      }
      .p-num {
        font-weight: bold;
        font-family: HuXiaoBo-NanShen;
        font-size: 1.25vw;
        color: #333333;
      }
      .jiantou {
        width: 2.29vw;
        height: 0.83vw;
        position: absolute;
        top: 2.08vw;
        right: 2.6vw;
        background: url("../assets/images/common/zhuangshi.png") no-repeat;
        background-size: cover;
      }
    }
  }
}
.mainBodyA {
  .tabhead {
    width: 100%;
    height: 2.81vw;
    line-height: 2.81vw;
    font-size: 0;
    background: #f3ca00;
    color: #fff;
  }
  .tabTr {
    font-size: 0;
    position: relative;
    width: 100%;
    height: 1.88vw;
    line-height: 1.88vw;
    // background-color: #f7f7f7;
    div {
      display: inline-block;
    }
  }
  .down {
    position: absolute;
    right: 0.52vw;
    color: #f3ca00;
    font-size: 0.83vw;
    top: 0;
  }
}

.space-collapse {
  /deep/ .el-collapse-item__header {
    height: auto;
    border-bottom: none;
    background-color: transparent;
    .el-collapse-item__arrow {
      display: none;
    }
  }
}
.collapseItem1 {
  background: #eeeeee;
}
.collapseItem2 {
  background: #f7f7f7;
}

.tabline25 {
  display: inline-block;
  width: 25%;
  font-size: 0.83vw;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.recruitCon {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  // z-index: -1;
  height: auto;
  // opacity: 0;
  padding: 1.56vw 2.08vw;
  transition: padding 0.1s;
  transition: 0.6s ease-in;
}
.recruitCon.on {
  padding: 1.56vw 2.08vw;
  height: auto;
  opacity: 1;
  z-index: 99;
}
.recruitCon_tit {
  color: #333333;
  font-size: 0.83vw;
  padding-left: 0.73vw;
  font-weight: bold;
  margin-bottom: 0.52vw;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 0.31vw;
    height: 0.83vw;
    background: #f3ca00;
    position: absolute;
    top: 0.31vw;
    left: 0;
  }
}
.recruitCon_bg {
  width: 27.86vw;
  color: #666666;
}
.marginR30 {
  margin-right: 1.56vw;
}
.recruitCon_con {
  padding-left: 0.78vw;
  // line-height: 1.46vw;
  // font-size: 0.73vw;
  word-wrap: break-word;
  word-break: break-all;
}
.recruitbtm {
  font-size: 0;
  // height: 1.77vw;
  line-height: 1.56vw;
  margin: 0 1.56vw;
  padding: 0.31vw 1.04vw;
  box-sizing: border-box;
  background: #eeeeee;
}
.recruitbtm_line {
  color: #666666;
  font-size: 0.73vw;
  margin-right: 2.6vw;
  display: inline-block;
}
.recruitbtm_line:last-child {
  margin-right: 0;
}
.recruitbtm_line > img {
  display: inline-block;
  width: 1.25vw;
  margin-right: 0.26vw;
  vertical-align: top;
  position: relative;
  top: 0.26vw;
}
.recruitbtm_ts {
  font-size: 0.73vw;
  color: #666666;
  text-align: center;
  margin-top: 2.08vw;
  // padding-bottom: 1.04vw;
}
.tabOcon {
  margin-bottom: 2.08vw;
  box-shadow: 0vw 0.16vw 0.36vw 0vw rgba(0, 0, 0, 0.15);
}
.pagecon {
  text-align: center;
  padding: 1.04vw 0;
}
@media (min-width: 1920px) {
  .recruit_con {
    font-size: 0;
    li {
      width: 285px;
      height: 211px;
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
      transition: 0.6s ease-in-out;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(1):hover {
        background: url("../assets/images/recruit/bg1on.png") no-repeat;
        background-size: 100%;
      }
      &:nth-child(2):hover {
        background: url("../assets/images/recruit/bg2on.png") no-repeat;
        background-size: 100%;
      }
      &:nth-child(3):hover {
        background: url("../assets/images/recruit/bg3on.png") no-repeat;
        background-size: 100%;
      }
      &:nth-child(4):hover {
        background: url("../assets/images/recruit/bg4on.png") no-repeat;
        background-size: 100%;
      }

      &:nth-child(1) {
        background: url("../assets/images/recruit/bg1.png") no-repeat;
        background-size: 100%;
      }
      &:nth-child(2) {
        background: url("../assets/images/recruit/bg2.png") no-repeat;
        background-size: 100%;
      }
      &:nth-child(3) {
        background: url("../assets/images/recruit/bg3.png") no-repeat;
        background-size: 100%;
      }
      &:nth-child(4) {
        background: url("../assets/images/recruit/bg4.png") no-repeat;
        background-size: 100%;
      }
    }
    .recruit_tit {
      margin-top: 50px;
      text-align: center;
    }
    .recruit_ftit {
      font-size: 14px;
      text-align: center;
    }
  }
  .c-space02 {
    width: 1200px;
    margin: 0 auto;
    .list-process {
      height: 158px;
      position: relative;
      .item-process {
        display: block;
        padding: 0 135px 0 0;
        text-align: center;
        // position: relative;
        transition-property: all;
        transition-duration: 0.6s;
        transition-timing-function: ease-in-out;
        position: absolute;
        top: 0;
        opacity: 0;
        &.hide {
          opacity: 0;
        }
        &.item-process1 {
          transition-delay: 0.7s;
          left: 0;
          &.show {
            opacity: 1;
            left: 0;
          }
        }
        &.item-process2 {
          transition-delay: 0.7s * 2;
          left: 0;
          &.show {
            opacity: 1;
            left: 222px * 1;
          }
        }
        &.item-process3 {
          transition-delay: 0.7s * 3;
          left: 222px * 1;
          &.show {
            opacity: 1;
            left: 222px * 2;
          }
        }
        &.item-process4 {
          transition-delay: 0.7s * 4;
          left: 222px * 2;
          &.show {
            opacity: 1;
            left: 222px * 3;
          }
        }
        &.item-process5 {
          transition-delay: 0.7s * 5;
          left: 222px * 3;
          &.show {
            opacity: 1;
            left: 222px * 4;
          }
        }
        &.item-process6 {
          left: 222px * 4;
          transition-delay: 0.7s * 6;
          &.show {
            opacity: 1;
            left: 222px * 5;
          }
        }
        &:last-child {
          padding-right: 0;
        }
        .p-icon {
          width: 88px;
          height: 88px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
          }
        }
        .p-icon {
          width: 88px;
          height: 88px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
          }
        }
        .p-text {
          color: #333333;
          padding: 10px 0;
          font-size: 16px;
        }
        .p-num {
          font-weight: bold;
          font-family: HuXiaoBo-NanShen;
          font-size: 24px;
          color: #333333;
        }
        .jiantou {
          width: 44px;
          height: 16px;
          position: absolute;
          top: 40px;
          right: 50px;
          background: url("../assets/images/common/zhuangshi.png") no-repeat;
          background-size: cover;
        }
      }
    }
  }
  .mainBodyA {
    .tabhead {
      width: 100%;
      height: 54px;
      line-height: 54px;
      font-size: 0;
      background: #f3ca00;
      color: #fff;
    }
    .tabTr {
      font-size: 0;
      position: relative;
      width: 100%;
      height: 36px;
      line-height: 36px;
      // background-color: #f7f7f7;
      div {
        display: inline-block;
      }
    }
    .down {
      position: absolute;
      right: 10px;
      color: #f3ca00;
      font-size: 16px;
      top: 0;
    }
  }

  .space-collapse {
    /deep/ .el-collapse-item__header {
      height: auto;
      border-bottom: none;
      background-color: transparent;
      .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .collapseItem1 {
    background: #eeeeee;
  }
  .collapseItem2 {
    background: #f7f7f7;
  }

  .tabline25 {
    display: inline-block;
    width: 25%;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .recruitCon {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    // z-index: -1;
    height: auto;
    // opacity: 0;
    padding: 30px 40px;
    transition: padding 0.1s;
    transition: 0.6s ease-in;
  }
  .recruitCon.on {
    padding: 30px 40px;
    height: auto;
    opacity: 1;
    z-index: 99;
  }
  .recruitCon_tit {
    color: #333333;
    font-size: 16px;
    padding-left: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 6px;
      height: 16px;
      background: #f3ca00;
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
  .recruitCon_bg {
    width: 535px;
    color: #666666;
  }
  .marginR30 {
    margin-right: 30px;
  }
  .recruitCon_con {
    padding-left: 15px;
    // line-height: 28px;
    // font-size: 14px;
    word-wrap: break-word;
    word-break: break-all;
  }
  .recruitbtm {
    font-size: 0;
    // height: 34px;
    line-height: 30px;
    margin: 0 30px;
    padding: 6px 20px;
    box-sizing: border-box;
    background: #eeeeee;
  }
  .recruitbtm_line {
    color: #666666;
    font-size: 14px;
    margin-right: 50px;
    display: inline-block;
  }
  .recruitbtm_line:last-child {
    margin-right: 0;
  }
  .recruitbtm_line > img {
    display: inline-block;
    width: 24px;
    margin-right: 5px;
    vertical-align: top;
    position: relative;
    top: 5px;
  }
  .recruitbtm_ts {
    font-size: 14px;
    color: #666666;
    text-align: center;
    margin-top: 20px;
    // padding-bottom: 20px;
  }
  .tabOcon {
    margin-bottom: 40px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
  }
  .pagecon {
    text-align: center;
    padding: 20px 0;
  }
}
.pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f3ca00;
  color: #fff;
}

.pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #f3ca00;
}
</style>
